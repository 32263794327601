<template>
  <div class="card" :class="[suit, `v-${value}`]" @click="$emit('click')">
    <div class="value">{{ value }}</div>
    <div class="suit">
      <svg v-if="suit === 'hearts'" viewBox="0 0 116 110" width="116" height="110" xmlns="http://www.w3.org/2000/svg">
        <path
          fill=" rgb(255, 0, 0)"
          d="m0,32.188c0,-17.777 14.411,-32.188 32.189,-32.188c10.726,0 20.227,5.246 26.077,13.314c5.87,-7.838 15.233,-13.292 25.779,-13.292c17.778,0 32.189,14.791 32.189,32.569c0,6.614 -1.995,12.762 -5.416,17.876l-52.429,59.793l-47.128,-53.614c-2.196,-2.839 -11.261,-14.672 -11.261,-24.458z"
        />
      </svg>
      <svg
        v-else-if="suit === 'spades'"
        viewBox="0 0 115 131"
        width="115"
        height="131"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m84.649,131.43601l-54.55,0l14.626,-25.333c-3.954,1.766 -8.337,2.748 -12.948,2.748c-17.55,0 -31.777,-14.227 -31.777,-31.776c0,-9.662 8.948,-21.343 11.117,-24.145l46.526,-52.93l51.758,59.03c3.377,5.047 5.347,11.117 5.347,17.647c0,17.55 -14.227,32.152 -31.777,32.152c-4.667,0 -9.098,-1.081 -13.089,-2.971l14.767,25.578z"
        />
      </svg>
      <svg
        v-else-if="suit === 'clubs'"
        viewBox="0 0 123 132"
        width="123"
        height="132"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m89.48599,132.064l-55.921,0l16.815,-29.124c-5.25,3.886 -11.747,6.183 -18.78,6.183c-17.452,0 -31.6,-14.148 -31.6,-31.6c0,-17.451 14.148,-31.599 31.6,-31.599c0.596,0 1.188,0.016 1.776,0.049c-2.206,-4.312 -3.451,-9.198 -3.451,-14.373c0,-17.452 14.148,-31.6 31.6,-31.6c17.452,0 31.6,14.148 31.6,31.6c0,5.176 -1.245,10.062 -3.451,14.374c0.596,-0.034 1.197,-0.05 1.803,-0.05c17.452,0 31.6,14.148 31.6,31.599c0,17.452 -14.148,31.6 -31.6,31.6c-7.053,0 -13.568,-2.311 -18.826,-6.218l16.835,29.159z"
        />
      </svg>
      <svg
        v-else-if="suit === 'diamonds'"
        viewBox="0 0 113 134"
        width="113"
        height="134"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill=" rgb(255, 0, 0)"
          d="m113.229,66.89843c-25.135,11.48 -45.52,35.624 -56.405,66.301c0,0 -0.137,0.79 -0.207,0.59c-0.071,0.2 -0.212,-0.676 -0.212,-0.676c-10.885,-30.677 -31.269,-54.768 -56.405,-66.249l0.004,0.007c25.135,-11.481 45.52,-35.572 56.405,-66.249c0,0 0.137,-0.79 0.208,-0.59c0.07,-0.2 0.207,0.59 0.207,0.59c10.885,30.677 31.27,54.768 56.405,66.249l0,0.027z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandardDeckCard',
  props: {
    suit: String,
    value: String,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  width: 62px;
  height: 88px;
  vertical-align: middle;
  user-select: none;

  &.selected {
    background-color: #ccc;
  }
  .value {
    font-size: 24px;
    font-weight: bold;
  }
}
.suit {
  width: 50%;

  svg {
    width: 100%;
    height: auto;
  }
}
</style>
